import { Layout } from './components/layout/Layout';
import './washingtoniafonts.css';
import './washingtoniastyle.css';
import React, { useEffect, useState } from "react";
import { Maintainance } from './components/popup/index';

function App() {
  const [showPopUp, setShowPopUp] = useState(true);
 
  useEffect(() => {
     const timer = setTimeout(() => {
     setShowPopUp(false);
   }, 5000);
  return () => clearTimeout(timer);
  }, []);
  return (
      <>
      {showPopUp ? <Maintainance/> : <Layout></Layout>}
      </>
  );
}

export default App;
