import React from 'react';
import { useNavigate } from "react-router-dom";
export const HomeBanner=()=>{
    const navigate = useNavigate();
    return(
        <div className="container">
            <div className="row">
                <div id="topsectionframe1" className="col-md-5">
                    <div className="row">
                        <div onClick={()=>navigate('/')} id="top1" className="col-md-12 p-2 homebanner-frame1">
                            <div className="col-md-12 cover homebanner-frame-cover">
                                <div id="top1zoom" className="homebanner-frame1-cover-bg">
                                    <div className="overlay"></div>
                                </div>
                                <div id="topheadings1">
                                    <div>
                                        <h3 className="p-5 homebanner-frame1-cover-topheading">Purchase from online store </h3>
                                    </div>
                                </div>
                                <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right homebanner-frame-icon" aria-hidden="true"></i>
                                <div className="homebanner-frame1-offer">
                                    <div id="topviewtab1" style={{cursor:'pointer'}}>
                                        <h4 style={{color:'white'}}>GET THIS OFFER NOW</h4>
                                        <div className="underlineaniation1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div id="topsectionframe2" className="col-md-7">
                    <div className="row">                        
                        <div onClick={()=>navigate('Indoor')} id="top2" className="col-md-6 p-2 homebanner-frame2">
                            <div className="col-md-12 cover homebanner-frame-cover">
                                <div id="top2zoom" className="homebanner-frame2-cover-bg">
                                    <div className="overlay"></div>
                                </div>
                                <div id="topheadings2">
                                    <h2 className="p-2 homebanner-frame2-cover-topheading">Indoor Plants</h2>
                                </div>
                                <i className="fas fa-umbrella fa-lg mt-4 me-2 float-right homebanner-frame-icon" aria-hidden="true"></i>
                                <div className="homebanner-frame1-offer">
                                    <div id="topviewtab2" style={{cursor:'pointer'}}>
                                        <h4 style={{color:'white'}}>VIEW ALL</h4>
                                        <div className="underlineaniation2"></div>
                                    </div>
                                </div>                              
                            </div>
                        </div>
                        <div onClick={()=>navigate('Outdoor')} id="top3" className="col-md-6 p-2 homebanner-frame2">
                            <div className="col-md-12 cover homebanner-frame-cover">
                                <div id="top3zoom" className="homebanner-frame2-outdoor-bg">
                                    <div className="overlay"></div>
                                </div>
                                <div id="topheadings3">
                                    <h2 className="p-2 homebanner-frame2-cover-topheading">Outdoor Plants</h2>
                                </div>
                                <i className="fas fa-sun fa-lg mt-4 me-2 float-right homebanner-frame-icon" aria-hidden="true"></i>
                                <div className="homebanner-frame1-offer">
                                    <div id="topviewtab3" style={{cursor:'pointer'}}>
                                        <h4 style={{color:'white'}}>VIEW ALL</h4>
                                        <div className="underlineaniation3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>navigate('Pots')} id="top4" className="col-md-6 p-2 homebanner-frame2">
                            <div className="col-md-12 cover homebanner-frame-cover">
                                <div id="top4zoom" className="homebanner-frame2-pots-bg">
                                    <div className="overlay"></div>
                                </div>
                                <div id="topheadings4">
                                    <h2 className="p-2 homebanner-frame2-cover-topheading">Pots</h2>
                                </div>
                                <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right homebanner-frame-icon" aria-hidden="true"></i>
                                <div className="homebanner-frame1-offer">
                                    <div id="topviewtab4" style={{cursor:'pointer'}}>
                                        <h4 style={{color:'white'}}>VIEW ALL</h4>
                                        <div className="underlineaniation4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div onClick={()=>navigate('Services')} id="top5" className="col-md-6 p-2 homebanner-frame2">
                            <div className="col-md-12 cover homebanner-frame-cover">
                                <div id="top5zoom" className="homebanner-frame2-services-bg">
                                    <div className="overlay"></div>
                                </div>
                                <div id="topheadings5">
                                    <h2 className="p-2 homebanner-frame2-cover-topheading">Maintenance</h2>
                                </div>
                                <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right homebanner-frame-icon" aria-hidden="true"></i>
                                <div className="homebanner-frame1-offer">
                                    <div id="topviewtab5" style={{cursor:'pointer'}}>
                                        <h4 style={{color:'white'}}>VIEW ALL</h4>
                                        <div className="underlineaniation5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>                
                </div>
            </div>
        </div>
    )
}