import {
    UPDATE_CART, ADD_TO_CART
} from './Constants';

const initialState = {
    cartData:''
}
export function cartReducer(state = initialState, action) {
    switch (action.type) {
      case UPDATE_CART:
        return {
            ...state,
            cartData: JSON.parse(localStorage.getItem("cartItems"))
       }
       default:
        return state;
    }
}