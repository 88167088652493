import React, { useEffect } from 'react';

export const Maintainance=()=>{
    
    return(
        <div className='maintainance-page'>
            <h3>This website is currently undergoing maintenance</h3>
            <br/>
            <br/>
            <br/>
            <p className='pop-bold'> ICV, ISO certification is under process </p>
            <br/>
            <br/>
        </div>
    )
}